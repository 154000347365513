import React from "react"
import Layout from "../components/layout"
import SEO from "../utils/seo"

const Contacte = () => (
  <Layout title="Vols ensenyar a través de l'art?">
    <SEO title="Treballa amb nosaltres" />
    <div className="container" style={{ marginTop: 40 }}>
      <p>
        Completa el següent formulari per entrar a la borsa de treball
        d'artistes pedagogs i et contactarem quan tinguem feina per a tu.
      </p>
      <a
        href="https://goo.gl/forms/FWGdUKEnrPrtZT2J3"
        alt="Treballa amb nosaltres"
      >
        <div
          className="tagline-link button button-medium menu-contrast-button"
          id="inscripcions-obertes"
        >
          Vull inscriure'm!
        </div>
      </a>
    </div>
  </Layout>
)

export default Contacte
